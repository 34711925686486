import React from "react"
import SEO from "../components/seo"
import FormCta from "../components/formCta/formCta"
import UspBand from "../components/uspBand/uspBand"
import QuoteBuilder from "../components/form/quoteBuilder"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import CaseStudyTeaser from "../components/caseStudyTeaser/caseStudyTeaser"
import CaseStudyTeaserImage from "../components/caseStudyTeaserImage/caseStudyTeaserImage"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


const CreativeServicePage = ({ data: { service }, location }) => {
  return (
    <>
      <SEO
        titleOverride={service.metaTags && service.metaTags.title ? service.metaTags.title : service.title}
        descriptionOverride={service.metaTags && service.metaTags.description ? service.metaTags.description : null}
        pathnameOverride={location.pathname}
        imageOverride={service.metaTags && service.metaTags.image ? service.metaTags.image.url : null}
      />

      <section className="mb-12 md:mb-20">
        <div className="container">
          <div className="w-full mx-auto md:w-10/12">
            <div className="w-full mx-auto text-center md:w-9/12 lg:w-7/12">
            <ol className="block mb-1">
                <li className="text-base text-grey-dark">
                  <Link to="/creative-services/" className="text-base underline transition duration-500 ease-in-out text-grey-dark hover:text-secondary-dark focus:text-secondary-dark">
                    <span>Creative</span>
                  </Link> /
                </li>
              </ol>
              
              <span className="block h1">{ service.heroHeading }</span>
              <div className="content content--reset" dangerouslySetInnerHTML={{ __html: service.heroExcerpt }}></div>
            </div>
          </div>
        </div>
      </section>

      <div className="w-full mx-auto mb-12 container--3xl md:mb-16 xl:mb-24">
        { service.heroVideoEmbed && (
          <div className="flex flex-wrap justify-center">
            <div className="w-full container flex justify-center">
              <a href="#form" className="mb-8 -mt-5 text-base capitalize btn btn--outline md:mb-12">Want to know more?</a>
            </div>
            <div className="w-full">
              <div className="w-full xl:w-10/12 mx-auto" dangerouslySetInnerHTML={{ __html: service.heroVideoEmbed}}></div>
            </div>
          </div>
        )}
      </div>

      { service.featuredWork.length > 0 && (
        <section className="pt-12 pb-5 mb-8 overflow-hidden md:mb-20 lg:mb-32 bg-grey-light">
          <div className="container">
            <div className="xl:w-10/12 md:mx-auto">
              <div className="flex flex-wrap mb-10 md:-mx-1 md:mb-12">
                {service.featuredWork.map(({ title, slug, teaserImage }, index) => {
                  return(
                    <div key={index} className="w-full mb-8 md:w-1/3 md:px-1 md:mb-0">
                      { teaserImage && (
                        <CaseStudyTeaserImage
                          url={`/work/${slug}`}
                          image={teaserImage.fluid}
                          title={title}
                        />
                      )}
                      { !teaserImage && (
                        <CaseStudyTeaser 
                        url={`/work/${slug}`}
                        title={title}
                      />
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
            
            <div>
              <p className="px-12 mb-8 text-xl leading-tight text-center align-middle lg:text-2xl font-display md:px-24">Get in touch today on <a href="tel:01159598900" className="inline-block break-all transition duration-500 ease-in-out text-primary hover:text-primary-dark focus:text-primary-dark contactlink">0115 959 8900</a> or <span className="block mt-3 ml-2 align-middle sm:mt-0 sm:inline-block"><a href="#form" className="text-base capitalize btn btn--outline">Get in touch</a></span></p>
            </div>
          </div>
        </section>
      )}

      <section className="relative z-10 mb-16 overflow-hidden lg:mb-32 xl:mb-40">
        <div className="container">
          <div className="flex flex-wrap lg:-mx-12 xl:items-center">
            <div className="w-full mb-5 lg:w-1/2 xl:w-5/12 lg:px-12 lg:mb-0">
              <div className="w-full content content--reset lg:pr-12 xl:pr-0" dangerouslySetInnerHTML={{ __html: service.content }}></div>
            </div>

            {service.contentImage && (
              <div className="w-full ml-auto lg:w-1/2 lg:px-12">
                <Img
                  backgroundColor={`#3B5CC4`}
                  fluid={service.contentImage.fluid}
                />
              </div>
            )}
          </div>
        </div>
      </section>

      { service.usps.length ? (
      <UspBand
        usps={service.usps}
      />
      ) : (
        <></>
      )}

      { service.uspsBlock.length ? (
        <section className="py-12 overflow-hidden text-white bg-secondary-dark md:py-20 xl:py-24">
          <div className="container">
            <div className="w-11/12 mx-auto">
              { service.uspsBlockHeading ? (
                <h2 className="mb-12 text-center text-secondary-light md:mb-20 xl:mb-24">{ service.uspsBlockHeading }</h2>
              ) : (
                <></>
              )}

              <div className="flex flex-wrap md:-mx-8">
                {service.uspsBlock.map(({icon, heading, text}, i) => {
                  return (
                    <div className="w-full mb-16 md:w-1/2 md:mb-24 md:px-8" key={i}>
                      <div className="relative px-8 border-l border-opacity-25 border-secondary-light md:px-12">
                      <div className="absolute top-0 left-0 w-px h-4 -ml-px bg-primary"></div>
                        { icon && (
                          <div className="flex items-center justify-center w-8 h-8 mb-4 rounded-full md:w-10 md:h-10 bg-primary text-secondary-dark">
                            <FontAwesomeIcon className="inline-block w-3 h-4 text-sm md:text-base" icon={ icon } size="sm" />
                          </div>
                        )}
                        { heading && (
                          <h4 className="text-primary">{ heading }</h4>
                        )}
                        { text && (
                          <div dangerouslySetInnerHTML={{ __html: text }}></div>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}

      { service.additionalContentHeading ? (
        <section className="py-12 overflow-hidden border-b md:py-20 xl:py-24 border-grey-light">
          <div className="container">
            <div className="w-full mx-auto md:w-11/12">
              <div className="flex flex-wrap md:-mx-6 lg:-mx-12">
                <div className="w-full mb-8 md:w-1/2 md:px-6 lg:px-12 md:mb-0">
                  {service.additionalContentImage && (
                    <div className="w-full">
                      <Img
                        backgroundColor={`#3B5CC4`}
                        fluid={service.additionalContentImage.fluid}
                      />
                    </div>
                  )}
                </div>
                <div className="w-full md:w-1/2 md:px-6 lg:px-12">
                  <div className="mb-12">
                    <h2>{ service.additionalContentHeading }</h2>
                  </div>
                  
                  <div className="content content--primary-list" dangerouslySetInnerHTML={{ __html: service.additionalContentText }}>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}

      
      { service.approachItem.length ? (
        <section className="py-12 overflow-hidden border-b md:py-20 xl:py-24 border-grey-light">
          <div className="container">
            { service.ourApproachHeading && (
              <h2 className="mb-12 text-center md:mb-20">{ service.ourApproachHeading }</h2>
            )}
            <div className="flex flex-wrap justify-center">
              <div className="w-full max-w-2xl">
                {service.approachItem.map(({heading, text}, i) => {
                  return (
                    <div className="relative w-full pb-8 pl-12 border-l md:pb-12 border-grey" key={i}>
                      <div className="absolute top-0 left-0 flex items-center justify-center w-8 h-8 -ml-4 font-bold text-white rounded-full bg-primary">{ i + 1 }</div>
                      <span className="block mb-2 text-lg font-bold md:text-xl">{ heading }</span>
                      <div className="content content--nofirst content--primary-list" dangerouslySetInnerHTML={{ __html: text }}></div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}


      { service.showQuoteBuilderForm && (
        <QuoteBuilder />
      )}

      <div id="form" className="mt-16 lg:mt-24">
        <FormCta />
      </div>
    </>
  )
}

export default CreativeServicePage

export const query = graphql`
  query CreativeServiceQuery($slug: String!) {
    service: datoCmsCreativeService(slug: { eq: $slug }) {
      title
      heroHeading
      heroExcerpt
      heroVideoEmbed
      content
      showAuditForm
      showQuoteBuilderForm
      contentImage {
        fluid(
          maxWidth: 720
          imgixParams: {h: "720", w: "720", fit: "crop", crop: "faces, edges"}) {
          ...GatsbyDatoCmsFluid
        }
      }
      featuredWork {
        ... on DatoCmsCreativeCaseStudy {
          title
          slug
          teaserImage {
            fluid(
              maxWidth: 740
              imgixParams: {h: "740", w: "740", fit: "crop", crop: "faces, edges"}) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
      }
      usps {
        heading
        blurb
      }
      metaTags {
        title
        description
        twitterCard
        image {
          url
        }
      }
      uspsBlockHeading
      uspsBlock {
        heading
        icon
        text
      }
      additionalContentHeading
      additionalContentText
      additionalContentImage {
        fluid(
          maxWidth: 720
          imgixParams: {h: "720", w: "720", fit: "crop", crop: "faces, edges"}) {
          ...GatsbyDatoCmsFluid
        }
      }
      ourApproachHeading
      approachItem {
        heading
        text
      }
    }
  }
`